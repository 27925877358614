.background{  
  background-color: #e3e3e3;
  display: block;
  min-height: 100vh;
}

.post-body {
  .separator{

    img {
      width: -webkit-fill-available;
      height: auto;
    }
  }
}

.pointer{
  cursor: pointer;
}